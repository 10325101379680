import { Injectable } from '@angular/core';
import { MinMaxDate } from '../../shared/interfaces/date';
import moment from 'moment';
import {BehaviorSubject} from "rxjs";
import {Observable} from "rxjs/internal/Observable";
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  dateFormatSubject$ = new BehaviorSubject<string>(this.dateFormat);
  dateFormatOptions = [
    'DD MMM YY',
    'DD/MM/YYYY',
    'DD/MM/YY',
    'MM/DD/YY',
    'MM/DD/YYYY',
    'YY/MM/DD',
    'YYYY/MM/DD'
  ];

  formatMasks = {
    'DD MMM YY': '00 AAA 00',
    'DD/MM/YYYY': '00/00/0000',
    'DD/MM/YY': '00/00/00',
    'MM/DD/YY': '00/00/00',
    'MM/DD/YYYY': '00/00/0000',
    'YY/MM/DD': '00/00/00',
    'YYYY/MM/DD': '0000/00/00',
  };

  setDateFormat(dateFormat: string) {
    this.dateFormatSubject$.next(dateFormat);
  }

  getDateFormat(): Observable<string> {
    return this.dateFormatSubject$.asObservable();
  }

  get dateFormat() {
    return localStorage.getItem('dateFormat') || 'DD MMM YY';
  }

  set dateFormat(value: string) {
    value ? localStorage.setItem('dateFormat', value) : localStorage.setItem('dateFormat', this.dateFormatOptions[0]) ;
  }

  getMinMaxDate(isStrictDate: boolean, ...minMaxYears: number[]): MinMaxDate {
    const minDate = moment().subtract(minMaxYears[1], 'years').add(1, 'day');
    const maxDate = moment().subtract(minMaxYears[0], 'years');
    const minMaxDate = new MinMaxDate();

   minMaxDate.minDate = {
      year: minDate.year(),
      month: isStrictDate ? (minDate.month() + 1) : 1,
      day: isStrictDate ? minDate.date() : 1
    };

    minMaxDate.maxDate = {
      year: maxDate.year(),
      month: isStrictDate ? (maxDate.month() + 1) : 12,
      day: isStrictDate ? maxDate.date() : 31
    };

    return minMaxDate;
  }

  formatDate(dateString: string, dateFormats: string | string[] = ['DDMMMYY', 'DD MMM YY', 'DD-MM-YY', 'DD/MM/YY', 'DD.MM.YY']) {
    return moment(dateString, dateFormats, true);
  }

  validateDate(formattedDate: any, minDate?: any, maxDate?: any): boolean {
    const formattedMoment = moment(formattedDate);

    if (minDate) {
      const minMoment = moment([minDate.year, minDate.month - 1, minDate.day]);
      if (formattedMoment.isBefore(minMoment)) {
        return false;
      }
    }

    if (maxDate) {
      const maxMoment = moment([maxDate.year, maxDate.month - 1, maxDate.day]);
      if (formattedMoment.isAfter(maxMoment)) {
        return false;
      }
    }

    return true;
  }

  getFormattedDate(parsedDate: moment.Moment) {
    return {
      year: parsedDate.year(),
      month: parsedDate.month() + 1,
      day: parsedDate.date(),
    };
  }

  updateDateControl(
    dateString: string,
    control: AbstractControl,
    dateFieldName: string,
    minDate?: any,
    maxDate?: any,
  ): void {
    const parsedDate = this.formatDate(dateString);

    if (parsedDate.isValid()) {
      const formattedDate = this.getFormattedDate(parsedDate);
      const isValid = this.validateDate(formattedDate, minDate, maxDate);

      if (isValid) {
        control.get(dateFieldName)?.patchValue(formattedDate);
      }
    }
  }

}
